// $primary: #a51616;
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,400');

$primary: #1060AB; /* MAIN COLOR */
$secondary: #66ED6B; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
html {
	overflow-x: hidden;
}
body {
	font-family: 'Josefin Sans', sans-serif;
	padding-right: 0 !important;
	
}
.flash {
	display:none;
}

nav {
	z-index: 1000;
}
.navbar-default {
	background-color: $wht;
}

nav.navbar {
	z-index: 1000;
	border: none;
	border-radius: $border-radius;

	.navbar-nav {
		>li>a {
			text-align: center;
			// margin-top: 22px;
			height: 100px;
			display: flex;
			align-items: center;
			color: $blk;
			font-size: 1em;

			@media (max-width: 767px) {
				margin-top: 0;
				padding: 8px;
				height: 30px;
				display: inline-block;
			}

			&:focus,
			&:active {
				background: transparent;
				color: $blk;
				outline: 0;
			}

			&:hover {
				background: $primary;
				color: $wht;
				border-bottom: 1px solid $wht;

				@media (max-width: 1024px) {
					background: transparent;
					color: $blk;
					outline: 0;
				}
			}
		}
	}
}

.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
	margin: 20px 15px 8px 0px;
	border: 1px solid transparent;
	border-radius: 4px;
}

p {
	font-family: 'Josefin Sans', sans-serif;

}
/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 350px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:none;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			box-shadow: none;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}
/** END LOGIN FORM **/

// start section
section {
	color: $wht;
	h1 {
		font-size: 2.5em;
		font-weight: bold;
		@media(max-width:767px){
			font-size: 1.8em;
		}
		@media(max-width:450px){
			font-size: 1.4em;
		}
	}
	p {
		font-size: 1.2em;
		line-height: auto;
	}
}
.pd {
	padding: 200px 0;
	@media (max-width:991px) {
		padding: 140px 0;
	}
	@media (max-width:767px) {
		padding: 100px 0;
	}
	@media (max-width:567px) {
		padding: 40px 0;
	}
	@media (max-width:450px) {
		padding: 30px 0;
	}
}
.pd2 {
	padding: 100px 0;
	@media (max-width:991px) {
		padding: 70px 0;
	}
	@media (max-width:767px) {
		padding:50px 0;
	}
	@media (max-width:567px) {
		padding: 30px 0;
	}
}
.cta-box {
	background: -moz-linear-gradient(left, #1060ab 0%, #66ed6b 100%);
	/* FF3.6-15 */
	background: -webkit-linear-gradient(left, #1060ab 0%, #66ed6b 100%);
	/* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right, #1060ab 0%, #66ed6b 100%);
	/* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1060ab', endColorstr='#66ed6b', GradientType=1);
	/* IE6-9 */
	@extend .pd2;
	ul {
		padding: 0;
		font-size: 1.2em;
		font-style: italic;
		li {
			list-style: none;
		}
	}
}
.hero-banner {
	@extend .pd;
	background: url('../img/bg3.jpg') no-repeat top/cover;
	margin-top: 100px;
	@media(max-width:767px){
		margin-top: 70px;;
	}
	.cta-text {
		margin-top: 15%;
			@media(max-width:767px) {
				margin-top: 5%;
				
			}
	}
}
.hero-image {
	background: url('../img/bg2.jpg') no-repeat top/cover;
	@extend .pd;
}
.hero-image-2 {
	background: url('../img/bg1.jpg') no-repeat top/cover;
	@extend .pd;
}
// ends sections
// cta starts
.cta {
	padding: 20px 40px;
	background: transparent;
	color: $wht;
	font-size: 1.2em;
	margin: 5px;
	border-radius: 0px;
	border: 1px solid $wht;
	@media(max-width:767px){
			padding: 10px 20px;
			font-size: 1.2em;
	}
	&:hover{
		border-radius:0px;
		color: $wht;
		background:$primary;
		transition: all 0.7s ease 0s;
	}
}

// cta ends
footer {
	padding: 50px 0px 20px;
	background: #1060ab; /* Old browsers */
	background: -moz-linear-gradient(left,  #1060ab 0%, #66ed6b 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(left,  #1060ab 0%,#66ed6b 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to right,  #1060ab 0%,#66ed6b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1060ab', endColorstr='#66ed6b',GradientType=1 ); /* IE6-9 */


	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}



.logo {
	max-height: 100px;
	@media(max-width:767px){
		max-height: 70px;
	}
}

.top-pad {
	padding-top:8em;
	@media(max-width:767px){
		padding-top: 5em;;
	}
}